import { useState } from 'react';
import { userColors, getTextColorClass } from '../utils/colors';

function ChangeUsername({ currentUsername, currentColor, onChangeUsername, onCancel, darkMode }) {
  const [newUsername, setNewUsername] = useState('');
  const [newColor, setNewColor] = useState(currentColor.replace('text-', 'bg-'));

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newUsername.trim() && newUsername !== currentUsername) {
      const colorClass = getTextColorClass(newColor);
      onChangeUsername(newUsername.trim().slice(0, 24), colorClass);
      console.log('Neue gewählte Farbe:', colorClass);
    }
  };

  return (
    <div className={`h-screen flex items-center justify-center ${darkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      <form onSubmit={handleSubmit} className={`${darkMode ? 'bg-gray-800' : 'bg-white'} p-8 rounded-lg shadow-md`}>
        <h2 className={`text-2xl font-bold mb-4 ${darkMode ? 'text-white' : 'text-gray-900'}`}>Nutzernamen ändern</h2>
        <input
          type="text"
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value.slice(0, 24))}
          placeholder="Neuer Nutzername (max. 24 Zeichen)"
          className={`w-full px-3 py-2 border rounded-md mb-4 ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-900 border-gray-300'}`}
          required
          maxLength={24}
        />
        <div className="mb-4">
          <label className={`block mb-2 ${darkMode ? 'text-white' : 'text-gray-900'}`}>Wähle eine Farbe:</label>
          <div className="flex flex-wrap gap-2">
            {userColors.map((color) => (
              <button
                key={color}
                type="button"
                className={`w-8 h-8 rounded-md ${color} ${newColor === color ? 'ring-2 ring-offset-2 ring-blue-500' : ''}`}
                onClick={() => setNewColor(color)}
              />
            ))}
          </div>
        </div>
        <div className="flex justify-between">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 flex-grow mr-2"
          >
            Ändern
          </button>
          <button
            type="button"
            onClick={onCancel}
            className={`${darkMode ? 'bg-gray-600 text-gray-200 hover:bg-gray-700' : 'bg-gray-300 text-gray-800 hover:bg-gray-400'} px-4 py-2 rounded-md flex-grow ml-2`}
          >
            Abbrechen
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChangeUsername;

import { useRef, useEffect, useState } from 'react';
import ContextMenu from './ContextMenu';

function ChatWindow({ messages, currentUsername, currentColor, darkMode, onDeleteMessage }) {
  const messagesEndRef = useRef(null);
  const [contextMenu, setContextMenu] = useState(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleContextMenu = (e, messageId) => {
    e.preventDefault();
    if (e.target.closest('.message-container').dataset.username === currentUsername) {
      setContextMenu({
        x: e.clientX,
        y: e.clientY,
        messageId: messageId
      });
    }
  };

  const handleDeleteMessage = () => {
    if (contextMenu) {
      onDeleteMessage(contextMenu.messageId);
      setContextMenu(null);
    }
  };

  const handleTouchStart = (e, messageId) => {
    if (e.target.closest('.message-container').dataset.username === currentUsername) {
      const timer = setTimeout(() => {
        setContextMenu({
          x: e.touches[0].clientX,
          y: e.touches[0].clientY,
          messageId: messageId
        });
      }, 500);
      return () => clearTimeout(timer);
    }
  };

  useEffect(() => {
    const handleClickOutside = () => setContextMenu(null);
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div className="flex-1 overflow-y-auto p-2 sm:p-4 space-y-4 bg-gray-100 dark:bg-gray-800 custom-scrollbar">
      {messages.map((msg, index) => (
        <div 
          key={msg.id || index} 
          className="flex flex-col items-start max-w-full message-container"
          data-username={msg.username}
          onContextMenu={(e) => handleContextMenu(e, msg.id)}
          onTouchStart={(e) => handleTouchStart(e, msg.id)}
        >
          {msg.type === 'system' ? (
            <div className="text-sm text-gray-500 dark:text-gray-400 italic text-center w-full">
              System: <span className={msg.oldColor}>{msg.oldUsername}</span> hat seinen Namen zu <span className={msg.newColor}>{msg.newUsername}</span> geändert.
            </div>
          ) : (
            <>
              <div className="flex items-center mb-1 max-w-full overflow-hidden">
                <span className={`text-lg font-semibold ${msg.color || currentColor} truncate`}>
                  {msg.username}
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400 ml-2 truncate">
                  - {msg.time || new Date(msg.timestamp).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}
                </span>
              </div>
              <div 
                className={`rounded-lg p-3 break-words ${
                  msg.username === currentUsername 
                    ? 'bg-blue-500 text-white' 
                    : darkMode ? 'bg-gray-700 text-gray-200' : 'bg-gray-200 text-gray-800'
                }`}
                style={{ maxWidth: '80%', wordWrap: 'break-word' }}
              >
                <p className="text-base">
                  {msg.deleted ? (
                    <span className="italic text-gray-500 dark:text-gray-400">Nachricht gelöscht</span>
                  ) : (
                    msg.message
                  )}
                </p>
              </div>
            </>
          )}
        </div>
      ))}
      <div ref={messagesEndRef} />
      {contextMenu && (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          onDelete={handleDeleteMessage}
          onClose={() => setContextMenu(null)}
        />
      )}
    </div>
  );
}

export default ChatWindow;

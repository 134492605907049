import './styles/customScrollbar.css';
import './styles/pageTransition.css';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import LandingPage from './components/LandingPage';
import Sidebar from './components/Sidebar';
import Chatbar from './components/Chatbar';
import ChatWindow from './components/ChatWindow';
import ChangeUsername from './components/ChangeUsername';
import PageTransition from './components/PageTransition';
import io from 'socket.io-client';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [socket, setSocket] = useState(null);
  const [darkMode, setDarkMode] = useState(true); // Ändern Sie den Standardwert auf true
  const [userColor, setUserColor] = useState('text-blue-500');
  const [currentPage, setCurrentPage] = useState('landing');
  const MAX_MESSAGE_LENGTH = 500;

  useEffect(() => {
    const savedUsername = Cookies.get('username');
    const savedColor = Cookies.get('userColor');
    if (savedUsername) {
      setUsername(savedUsername);
      setCurrentPage('chat'); // Setze die Seite auf 'chat', wenn ein Benutzername gefunden wurde
    }
    if (savedColor) {
      setUserColor(savedColor);
    }
  }, []);

  useEffect(() => {
    if (username) {
      Cookies.set('username', username, { expires: 7 }); // Cookie läuft nach 7 Tagen ab

      const newSocket = io('https://lnnr.de', {
        path: '/socket.io'
      });
      setSocket(newSocket);

      newSocket.on('connect', () => {
        console.log('Socket verbunden');
        newSocket.emit('user joined', username);
      });

      newSocket.on('load messages', (loadedMessages) => {
        setMessages(loadedMessages);
      });

      newSocket.on('chat message', (msg) => {
        console.log('Empfangene Nachricht:', msg); // Fügen Sie diese Zeile hinzu
        setMessages((prevMessages) => [...prevMessages, msg]);
      });

      newSocket.on('update users', (updatedUsers) => {
        setUsers(updatedUsers);
      });

      newSocket.on('change username', (data) => {
        if (data.oldUsername === username) {
          setUsername(data.newUsername);
          Cookies.set('username', data.newUsername, { expires: 7 });
        }
      });

      newSocket.on('message deleted', (deletedMessageId) => {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === deletedMessageId ? { ...msg, deleted: true } : msg
          )
        );
      });

      return () => newSocket.close();
    }
  }, [username]);

  useEffect(() => {
    const savedDarkMode = Cookies.get('darkMode');
    if (savedDarkMode === null || savedDarkMode === undefined) {
      // Wenn kein Cookie gesetzt ist, verwenden wir den Standardwert (true)
      setDarkMode(true);
      Cookies.set('darkMode', true, { expires: 365 });
    } else {
      setDarkMode(savedDarkMode === 'true');
    }
  }, []);

  useEffect(() => {
    Cookies.set('darkMode', darkMode, { expires: 365 });
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  useEffect(() => {
    document.title = 'Live-Chat';
  }, []);

  const sendMessage = (message) => {
    if (socket && message.trim()) {
      const trimmedMessage = message.trim().slice(0, MAX_MESSAGE_LENGTH);
      const msg = { username, message: trimmedMessage, color: userColor };
      socket.emit('chat message', msg);
    }
  };

  const handleChangeUsername = (newUsername, newColor) => {
    if (socket) {
      socket.emit('change username', { 
        oldUsername: username, 
        newUsername, 
        oldColor: userColor, 
        newColor 
      });
      setUsername(newUsername);
      setUserColor(newColor);
      Cookies.set('username', newUsername, { expires: 7 });
      Cookies.set('userColor', newColor, { expires: 7 });
      setIsSidebarOpen(false); // Schließt die Sidebar auf mobilen Geräten
      setCurrentPage('chat');
    }
  };

  const handleDeleteMessage = (messageId) => {
    if (socket) {
      socket.emit('delete message', messageId);
    }
  };

  const renderCurrentPage = () => {
    switch (currentPage) {
      case 'landing':
        return (
          <LandingPage
            setUsername={(name) => {
              setUsername(name);
              setCurrentPage('chat');
            }}
            setUserColor={setUserColor}
          />
        );
      case 'chat':
        return (
          <div className={`h-screen flex flex-col ${darkMode ? 'dark' : ''}`}>
            <div className="flex-1 flex overflow-hidden">
              <Sidebar
                isOpen={isSidebarOpen}
                setIsOpen={setIsSidebarOpen}
                username={username}
                users={users}
                onChangeUsernameClick={() => setCurrentPage('changeUsername')}
                darkMode={darkMode}
                setDarkMode={setDarkMode}
              />
              <main className="flex-1 flex flex-col bg-gray-100 dark:bg-gray-800 pt-16 lg:pt-0 overflow-hidden">
                <ChatWindow 
                  messages={messages} 
                  currentUsername={username} 
                  currentColor={userColor} 
                  darkMode={darkMode} 
                  onDeleteMessage={handleDeleteMessage}
                />
                <Chatbar sendMessage={sendMessage} darkMode={darkMode} />
              </main>
            </div>
          </div>
        );
      case 'changeUsername':
        return (
          <ChangeUsername
            currentUsername={username}
            currentColor={userColor}
            onChangeUsername={handleChangeUsername}
            onCancel={() => setCurrentPage('chat')}
            darkMode={darkMode}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="app-container">
      <PageTransition location={currentPage}>
        <div className="page">
          {renderCurrentPage()}
        </div>
      </PageTransition>
    </div>
  );
}

export default App;

import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { userColors, getTextColorClass } from '../utils/colors';

function LandingPage({ setUsername, setUserColor }) {
  const [inputUsername, setInputUsername] = useState('');
  const [selectedColor, setSelectedColor] = useState('bg-blue-500');
  const darkMode = true; // Setzen Sie dies auf einen festen Wert, wenn es nicht geändert werden soll

  useEffect(() => {
    const savedUsername = Cookies.get('username');
    const savedColor = Cookies.get('userColor');
    if (savedUsername) setInputUsername(savedUsername);
    if (savedColor) setSelectedColor(savedColor.replace('text-', 'bg-'));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputUsername.trim()) {
      const colorClass = getTextColorClass(selectedColor);
      setUsername(inputUsername.trim().slice(0, 24));
      setUserColor(colorClass);
      Cookies.set('userColor', colorClass, { expires: 7 });
      console.log('Gewählte Farbe:', colorClass);
    }
  };

  return (
    <div className={`h-screen flex items-center justify-center ${darkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      <form onSubmit={handleSubmit} className={`${darkMode ? 'bg-gray-800' : 'bg-white'} p-8 rounded-lg shadow-md`}>
        <h1 className={`text-2xl font-bold mb-4 ${darkMode ? 'text-white' : 'text-gray-900'}`}>Live-Chat</h1>
        <input
          type="text"
          value={inputUsername}
          onChange={(e) => setInputUsername(e.target.value)}
          placeholder="Nutzername eingeben"
          className={`w-full px-3 py-2 border rounded-md mb-4 ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-900 border-gray-300'}`}
          required
        />
        <div className="mb-4">
          <label className={`block mb-2 ${darkMode ? 'text-white' : 'text-gray-900'}`}>Wähle eine Farbe:</label>
          <div className="flex flex-wrap gap-2">
            {userColors.map((color) => (
              <button
                key={color}
                type="button"
                className={`w-8 h-8 rounded-md ${color} ${selectedColor === color ? 'ring-2 ring-offset-2 ring-blue-500' : ''}`}
                onClick={() => setSelectedColor(color)}
              />
            ))}
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
        >
          Chat beitreten
        </button>
      </form>
    </div>
  );
}

export default LandingPage;

import { useState } from 'react';

const MAX_MESSAGE_LENGTH = 500;

function Chatbar({ sendMessage, darkMode }) {
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()) {
      sendMessage(message.trim().slice(0, MAX_MESSAGE_LENGTH));
      setMessage('');
    }
  };

  const handleChange = (e) => {
    setMessage(e.target.value.slice(0, MAX_MESSAGE_LENGTH));
  };

  const currentChars = message.length;

  return (
    <div className="bg-white dark:bg-gray-900 border-t dark:border-gray-700 p-2 sm:p-4 w-full">
      <form onSubmit={handleSubmit} className="flex flex-col">
        <div className="flex items-center mb-1">
          <input
            type="text"
            value={message}
            onChange={handleChange}
            placeholder="Nachricht eingeben..."
            className="flex-grow min-w-0 border rounded-l-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:text-white dark:border-gray-600"
          />
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-r-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 whitespace-nowrap"
          >
            Senden
          </button>
        </div>
        <div className={`text-sm ${currentChars > MAX_MESSAGE_LENGTH - 50 ? 'text-red-500' : 'text-gray-500'} dark:text-gray-400 self-end`}>
          {currentChars}/{MAX_MESSAGE_LENGTH}
        </div>
      </form>
    </div>
  );
}

export default Chatbar;

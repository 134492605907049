import React from 'react';

const ContextMenu = ({ x, y, onDelete, onClose }) => {
  return (
    <div 
      className="absolute bg-white dark:bg-gray-700 shadow-md rounded-md py-2 z-50"
      style={{ top: `${y}px`, left: `${x}px` }}
    >
      <button 
        className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-white"
        onClick={onDelete}
      >
        Nachricht löschen
      </button>
    </div>
  );
};

export default ContextMenu;

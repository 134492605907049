import { FaPencilAlt, FaMoon, FaSun } from 'react-icons/fa';

function Sidebar({ isOpen, setIsOpen, username, users, onChangeUsernameClick, darkMode, setDarkMode }) {
  console.log('Benutzerliste in Sidebar:', users);

  // Sortiere die Benutzer so, dass der eigene Nutzer zuerst kommt
  const sortedUsers = [
    ...users.filter(user => user.username === username),
    ...users.filter(user => user.username !== username)
  ];

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="fixed z-50 top-4 left-4 p-2 rounded-md bg-blue-500 text-white lg:hidden"
        aria-label="Toggle menu"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
      <div
        className={`fixed inset-y-0 left-0 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } lg:relative lg:translate-x-0 transition duration-200 ease-in-out z-30 w-74 bg-white dark:bg-gray-900 overflow-y-auto flex flex-col
        shadow-[5px_0_15px_rgba(0,0,0,0.3)]`}
      >
        <div className="p-4 mt-16 lg:mt-0">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold dark:text-white">- Nutzerliste -</h2>
            <button
              onClick={() => setDarkMode(!darkMode)}
              className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
            >
              {darkMode ? <FaSun size={20} /> : <FaMoon size={20} />}
            </button>
          </div>
          <div className="p-4">
            <p className="font-medium mb-2 dark:text-white">Aktuell verbunden ({users.length}):</p>
            <div className="space-y-2">
              {sortedUsers.map((user, index) => (
                <div key={index} className={`rounded-lg p-2 ${user.username === username ? 'bg-blue-100 dark:bg-blue-900' : 'bg-gray-100 dark:bg-gray-800'}`}>
                  <p className={`${user.username === username ? 'text-blue-500 dark:text-blue-300 font-semibold' : 'text-gray-700 dark:text-gray-300'}`}>
                    {user.username} {user.username === username ? '(Du)' : ''}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-auto p-4 border-t dark:border-gray-700">
          <div className="flex justify-between items-center">
            <span className="text-sm text-gray-600 dark:text-gray-400">Namen ändern: {username}</span>
            <button
              onClick={onChangeUsernameClick}
              className="text-blue-500 dark:text-blue-300 hover:text-blue-600 dark:hover:text-blue-400 focus:outline-none"
              title="Nutzernamen ändern"
            >
              <FaPencilAlt size={16} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;

import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import '../styles/pageTransition.css';

const PageTransition = ({ children, location }) => {
  return (
    <TransitionGroup>
      <CSSTransition
        key={location}
        classNames="fade"
        timeout={300}
        mountOnEnter
        unmountOnExit
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
};

export default PageTransition;
